import { Tooltip } from '@mui/material';
import { useState } from 'react';

const UWWrappedBadge = ({ item }) => {
  if (!item) return null;
  if (!item.collection) return null;
  if (!item.collection.mirrorAddress) return null;

  if (item.collection.contractType !== 'DN404') return null;

  return (
    <div
      style={{
        //filter: `grayscale(${rarityRatio}%)`,
        background: '#9172FF',
      }}
      className={`via-red-500 absolute left-3 bottom-3 z-[1] flex min-w-[50px] cursor-pointer items-center justify-between rounded-lg border-jacarta-100  px-2 py-1 text-white transition-all group-hover/price:bg-accent dark:text-[#FFF]`}
    >
      <div className='ml-[2px] font-display text-sm'>
        <div>Wrapped By</div>
        <p className='text-xs'>Universal Wrapper</p>
      </div>
    </div>
  );
};

export default UWWrappedBadge;

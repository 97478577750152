import { constants } from './constants';
import { Chain } from 'wagmi';

export const supportedChains = constants.helpers.supportedChains;

/**
 * @param {Chain['hexId'] | Chain['id']} chain
 * @returns {Chain & {
 * hexId: string,
 * scan_url: string,
 * wrappedToken: string,
 * marketplace: string,
 * treasuryAddress: string,
 * lendingContract: string,
 * unsupported: boolean,
 * uwSupport: boolean,
 * uwManager: string,
 * uwVault: string
 * }}
 */
export const getSupportedChain = (chain) => {
  return (
    supportedChains.find((c) => c.hexId === chain || c.id === chain) ||
    supportedChains.find((c) => c.id === 56)
  );
};

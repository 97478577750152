import { createContext, useContext } from 'react';

export const DataRefreshContext = createContext({
  refreshDate: new Date(),
  refreshHooks: () => {},
});

export const useDataRefreshContext = () => {
  return useContext(DataRefreshContext);
};

import { isProd } from '../helpers/constants';

const hero_3_swiper_data = ({ walletAddress }) => [
  {
    id: 'universalWrapper',
    image: 'pi/banner-uw-desktop.png',
    mobileImage: 'pi/banner-uw-mobile.png',
    title: 'Universal Wrapper (Beta)',
    name: `Unlock new use cases and enable your community to grow beyond it's previous limits by turning a traditional NFT collection into a more versatile one. Transform ERC-720s into DN404s with the Universal Wrapper.`,
    titleLink: '/universal-wrapper',
    userLink: '/universal-wrapper',
    buttonText: 'Explore Now',
    connect: true,
  },
  {
    id: 'ragdoll',
    image: 'pi/banner-ragdoll-sold-out-desktop.png',
    mobileImage: 'pi/banner-ragdoll-sold-out-mobile.png',
    title: `Rag Dolls Mint Sold Out! Discover the DN404 Collection on Blast Network and embark on an unforgettable adventure! 
    A new way to trade NFTS.`,
    titleLink: isProd
      ? `/collection/0x13e31/ragdolls`
      : `/collection/0xa0c71fd/rag_dolls_blast_sepolia`,
    userLink: isProd
      ? `/collection/0x13e31/ragdolls`
      : `/collection/0xa0c71fd/rag_dolls_blast_sepolia`,
    buttonText: 'Explore Collection',
    connect: true,
  },
  {
    id: 'bulktool',
    image: 'pi/banner-bulktool.png',
    mobileImage: 'pi/banner-bulktool-mobile.png',
    title: 'Bulk Tool & Floor Sweeping Made Easy!',
    name: `Save time and don't miss the opportunities with our new Bulk Tool and Floor Sweep features!`,
    titleLink: walletAddress ? `/user/${walletAddress}?active=8#tabs` : '#',
    userLink: walletAddress ? `/user/${walletAddress}?active=8#tabs` : '#',
    buttonText: 'Discover Now',
    connect: true,
  },
  {
    id: 'squadSwap-widget',
    image: 'pi/banner-swap-widget.png',
    mobileImage: 'pi/banner-swap-widget-mobile.png',
    title: 'Powered by SquadSwap for Instant Swapping',
    name: 'Exchange your favorite tokens with just a few clicks directly from the Bit5 homepage.',
    titleLink: '/#squadswapWidget',
    userLink: '/#squadswapWidget',
    buttonText: 'Try It Now',
  },
  {
    id: 'squadSwap',
    image: 'pi/banner-ise-over.webp',
    mobileImage: 'pi/banner-squadswap-ise-mobile.webp',
    title: '',
    name: 'Squadswap ISE (Initial Swap Event) is coming! By participating in this event you can swap CAKE for SQUAD before trading goes live. Are you ready for a new era of community run DEXs?',
    titleLink: `/token/minting/${
      process.env.NEXT_PUBLIC_ENV_VARIABLE === 'production'
        ? '0x6D2d8AD6f7c044fBF7Bbd3677D40FbBA732f8ae1'
        : '0xdCf2039FCA69293819e71e245016799e316BAbfD'
    }`,
    userLink: `/token/minting/${
      process.env.NEXT_PUBLIC_ENV_VARIABLE === 'production'
        ? '0x6D2d8AD6f7c044fBF7Bbd3677D40FbBA732f8ae1'
        : '0xdCf2039FCA69293819e71e245016799e316BAbfD'
    }`,
    buttonText: 'Go to SquadSwap ISE',
  },
  {
    id: 'hacken',
    image: 'pi/hacken-banner-1920-1080.png',
    mobileImage: 'pi/banner-hacken-mobile.png',
    title: '',
    name: 'Bit5 successfully underwent a security audit, achieving a security score of 10 and an audit score of 9.2. These outstanding results underscore the strength of our design and implementation choices.',
    titleLink: 'https://hacken.io/audits/bit5/',
    userLink: 'https://hacken.io/audits/bit5/',
    buttonText: 'See the Audit',
    target: '_blank',
  },
  {
    id: 'quill',
    image: 'pi/quill-banner-1920-1080.png',
    mobileImage: 'pi/banner-quillaudit-mobile.png',
    title: '',
    name: "Bit5's pentest scope encompassed thorough analysis of Web App, WebSocket, and associated API endpoints, ensuring top-notch quality, security, and accuracy.",
    titleLink: 'https://www.quillaudits.com/leaderboard/bit5',
    userLink: 'https://www.quillaudits.com/leaderboard/bit5',
    buttonText: 'See the Report',
    target: '_blank',
  },
  {
    id: 'dashboard',
    image: 'images/dashboard-1920-1080.png',
    mobileImage: 'images/dashboard-430-600.png',
    title: 'Your journey starts here! Edit your dashboard, express your unique style.',
    name: '',
  },
  {
    id: '0Home',
    image: 'images/bit5_h3_1_1980_1020.png',
    mobileImage: 'images/bit5_h3_1_430_600.png',
    title: 'Providing progressive, secure and entertaining products for Crypto Community',
    name: '',
    titleLink: '/collection/explore_nfts',
    userLink: '/collection/explore_nfts',
    buttonText: 'Find Out More',
  },
  {
    id: '1TreasuryMoonies',
    image: 'images/moonies-1920-1080.png',
    mobileImage: 'images/moonies-430-600.png',
    title: 'Hello Moonies',
    name:
      '<ul><li>We are happy to announce that MoonPets will be the first collections added to our Treasury system. <li/>' +
      '<li>Explore MoonPets, the first Dynamic NFT collection on BNB chain. <li/>' +
      '<ul/>',
    titleLink: '/collection/0x38/0xE32aE22Ec60E21980247B4bDAA16E9AEa265F919',
    userLink: '/collection/0x38/0xE32aE22Ec60E21980247B4bDAA16E9AEa265F919',
    buttonText: 'Find Out More',
  },
];

const hero_3_swiper_thumbs_data = [
  {
    id: '0HoTm',
    image: '/images/bit5_h3_1_1980_1020.png',
  },
  {
    id: '1TrTh',
    image: '/images/treasury-banner-1920-1080-i.png',
  },
  {
    id: '1MoonrTh',
    image: '/images/moonies-1920-1080.png',
  },
  /*{
		id: '2/images/hero/hero_slide_3_thumb.jpg',
		image: '/images/hero/hero_slide_3_thumb.jpg',
	},
	{
		id: '3/images/hero/hero_slide_4_thumb.jpg',
		image: '/images/hero/hero_slide_4_thumb.jpg',
	},
	{
		id: '4/images/hero/hero_slide_1_thumb.jpg',
		image: '/images/hero/hero_slide_1_thumb.jpg',
	},
	{
		id: '5/images/hero/hero_slide_4_thumb.jpg',
		image: '/images/hero/hero_slide_4_thumb.jpg',
	},*/
];

export { hero_3_swiper_data, hero_3_swiper_thumbs_data };
